import React from 'react'

type MedShrLogoProps = {
  width?: number
  id?: string
  color?: string
}

// ////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////

const MedShrLogo = (props: MedShrLogoProps) => {
  const ratio = 225 / 210
  const width = props.width ?? 225
  const height = Math.round(width / ratio)
  const id = props.id ?? 'medshr-logo'
  const titleId = `${id}__title`
  const color = props.color ?? '#FFFFFF'

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 -10 256 256"
      aria-labelledby={titleId}
    >
      <title id={titleId}>MedShr</title>
      <g>
        <path
          d="M58.2872276,222.17641 L1.82068966,122.745897 C-0.606896552,118.470513 -0.606896552,111.477692 1.82068966,107.202308 L58.2872276,7.77358974 C60.7148138,3.49820513 66.6734345,0 71.5286069,0 L184.463448,0 C189.318621,0 195.277241,3.49820513 197.704828,7.77358974 L254.1696,107.202308 C256.597186,111.477692 256.597186,118.470513 254.1696,122.745897 L197.704828,222.17641 C195.277241,226.451795 189.318621,229.948205 184.463448,229.948205 L71.5286069,229.948205 C66.6734345,229.948205 60.7148138,226.451795 58.2872276,222.17641 Z"
          stroke={color}
          strokeWidth="13"
          fill="none"
        />
        <path
          d="M74.1517241,165.505128 C74.1517241,169.058974 76.8529655,171.805128 80.1897931,171.805128 C83.6855172,171.805128 86.3867586,169.058974 86.3867586,165.505128 L86.3867586,81.1820513 L121.82069,134.166667 C123.250759,136.266667 124.998621,137.397436 127.223172,137.397436 C129.447724,137.397436 131.03669,136.266667 132.466759,134.166667 L168.059586,81.0205128 L168.059586,165.34359 C168.059586,168.735897 170.919724,171.805128 174.415448,171.805128 C177.752276,171.805128 180.612414,168.897436 180.612414,165.34359 L180.612414,63.7358974 C180.612414,60.3435897 177.752276,57.4358974 174.415448,57.4358974 L172.985379,57.4358974 C170.443034,57.4358974 168.377379,58.7282051 167.106207,60.8282051 L127.382069,121.566667 L87.657931,60.8282051 C86.3867586,58.8897436 84.48,57.4358974 81.7787586,57.4358974 L80.3486897,57.4358974 C77.0118621,57.4358974 74.1517241,60.3435897 74.1517241,63.7358974 L74.1517241,165.505128 Z"
          fill={color}
        />
      </g>
    </svg>
  )
}

// /////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////// EXPORT DEFAULT
export default MedShrLogo
