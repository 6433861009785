import Link from 'next/link'
import MedShrLogo from '../../ui/medshr-logo'
import MedShrLogoText from '../../ui/medshr-logo-text'

export const LargeHeader = ({ children }: { children: React.ReactNode }) => (
  <header className="large-header fs-unmask">
    <div className="large-header__content-container">
      <div className="large-header__content">
        <div className="page-content">
          <div className="site-header__logo">
            <Link href="/">
              <span className="site-header__logo-link">
                <MedShrLogo width={30} />
                <MedShrLogoText />
              </span>
            </Link>
          </div>
          {children}
        </div>
      </div>
    </div>
    <img alt="" src="/static/images/header-angle-shape.svg?v=1" />
  </header>
)
